import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconArrowLeft, IconDownload, IconEye, IconShoppingBag, IconTag } from '@tabler/icons-react';
import { Breadcrumb, Button, Card, Col, Empty, Flex, Image, Row, Spin, message, notification } from 'antd';

import SourceModun from './SourceModun';
import SourceImage from './SourceImage';
import IconQuestion from '~/assets/icon/IconQuestion';
import imageNotFound from '~/assets/image/image_not.jpg';
import config, { convertCurrency, convertTimeUnit } from '~/configs';
import { loginUserSuccess, logoutUserSuccess } from '~/redux/reducer/auth';
import { requestGetSourceBySlug, requestAddSourceCart } from '~/services/source';

const { login, home, source_free, source_pay_fee, carts } = config.router;

function Detail() {
    const [source, setSource] = useState(null);
    const [loading, setLoading] = useState(false);

    const { slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        if (slug) {
            const fetch = async () => {
                setLoading(true);
                const result = await requestGetSourceBySlug(slug);

                setLoading(false);
                if (result.status === 401 || result.status === 403) {
                    dispatch(logoutUserSuccess());
                    navigate(`${login}?redirect=${pathname}`);
                } else if (result?.status === 200) {
                    setSource(result.data);
                    document.title = `Thegioicode.vn - ${result.data.title}`;
                } else {
                    navigate(source_free);
                    notification.error({
                        message: 'Thông báo',
                        description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                    });
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    const handleAddSourceCart = async () => {
        if (!slug) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn mã nguồn để thanh toán',
            });
        }

        const result = await requestAddSourceCart(slug);

        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${login}?redirect=${pathname}`);
        } else if (result?.status === 200) {
            const { carts_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ carts_count: carts_count + 1, ...others }));

            navigate(carts);
            message.success(result.message);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Row style={{ rowGap: 16 }}>
            <Col span={24}>
                <Flex className="gap-2 pl-2">
                    <Button size="small" className="box-center" onClick={() => navigate(source?.price > 0 ? source_pay_fee : source_free)}>
                        <IconArrowLeft size={18} />
                    </Button>
                    <Breadcrumb
                        className="flex-1"
                        items={[
                            {
                                title: <Link to={home}>Trang chủ</Link>,
                            },
                            {
                                title: (
                                    <Link to={source?.price > 0 ? source_pay_fee : source_free}>
                                        Mã nguồn {source?.price > 0 ? 'trả phí' : 'miễn phí'}
                                    </Link>
                                ),
                            },
                            {
                                title: source?.title,
                            },
                        ]}
                    />
                </Flex>
            </Col>
            <Col md={20} xs={24} style={{ padding: '0 8px' }}>
                <Card>
                    {!loading && source ? (
                        <Row style={{ margin: '0 -14px' }}>
                            <Col md={6} xs={24} style={{ padding: '0 14px' }}>
                                <Image width="100%" src={source.image_url} alt={source.title} fallback={imageNotFound} className="border" />
                            </Col>

                            <Col md={14} xs={24} style={{ padding: '0 14px' }}>
                                <h1 className="font-size-22 text-uppercase font-max mb-4">
                                    {source.title} - <span className="text-danger font-size-20">{source.version}</span>
                                </h1>
                                <div className="text-subtitle mb-xs-2">
                                    <p className="font-size-15" style={{ textAlign: 'justify' }}>
                                        {source.description}
                                    </p>
                                </div>
                            </Col>
                            <Col md={4} xs={24} style={{ padding: '0 14px' }}>
                                {source.price > 0 && (
                                    <Fragment>
                                        <h3 className="font-size-16 font-bold mb-4 d-flex align-items-center justify-content-between">
                                            Giá bán:
                                            {convertTimeUnit(source.usage_time.value, source.usage_time.unit) !== 'Vĩnh viễn' && (
                                                <IconQuestion
                                                    className="ml-4 text-subtitle"
                                                    title="Giá bán có thời gian sử dụng là dịch vụ cho thuê theo thời gian."
                                                />
                                            )}
                                        </h3>

                                        <div className="d-flex align-items-center">
                                            <IconTag size={20} className="text-subtitle" style={{ transform: 'rotate(90deg)' }} />
                                            <span className="text-danger font-size-20 font-bold mx-1">{convertCurrency(source.price)}</span>

                                            {convertTimeUnit(source.usage_time.value, source.usage_time.unit) !== 'Vĩnh viễn' && (
                                                <span className="text-subtitle font-bold">
                                                    / {convertTimeUnit(source.usage_time.value, source.usage_time.unit)}
                                                </span>
                                            )}
                                        </div>
                                        <span className="text-subtitle font-size-16 font-semibold d-block pl-4 text-line-through mb-5">
                                            {convertCurrency(source.old_price)}
                                        </span>
                                    </Fragment>
                                )}

                                <div>
                                    <a href={source.demo_url} target="_blank" rel="noreferrer">
                                        <Button type="primary" className="w-full bg-warning d-flex justify-content-center">
                                            <IconEye size={22} />
                                            <span className="text-uppercase ml-1">Xem demo</span>
                                        </Button>
                                    </a>
                                    {source.price > 0 &&
                                    convertTimeUnit(source.usage_time.value, source.usage_time.unit) !== 'Vĩnh viễn' ? (
                                        <a href="https://zalo.me/0706661234" target="_blank" rel="noreferrer">
                                            <Button type="primary" className="w-full mt-3 d-flex justify-content-center">
                                                <IconDownload size={20} />
                                                <span className="text-uppercase ml-1">Tải báo giá</span>
                                            </Button>
                                        </a>
                                    ) : (
                                        <Button
                                            type="primary"
                                            className="w-full mt-3 d-flex justify-content-center"
                                            onClick={handleAddSourceCart}
                                        >
                                            <IconShoppingBag size={20} />
                                            <span className="text-uppercase ml-1">Mua ngay</span>
                                        </Button>
                                    )}
                                </div>
                            </Col>

                            <Col span={24} className="pt-5">
                                <div className="title-custom font-size-16 text-uppercase">Ảnh demo</div>

                                <SourceImage images={source.image_meta} />
                            </Col>

                            <Col span={24} className="pt-5">
                                <div className="title-custom font-size-16 text-uppercase">Chi tiết phần mềm</div>

                                <SourceModun
                                    details={source.details.map((detail, index) => {
                                        return {
                                            key: Math.random(),
                                            index: index + 1,
                                            ...detail,
                                        };
                                    })}
                                />
                            </Col>

                            <Col span={24} className="pt-5">
                                <div className="title-custom font-size-16 text-uppercase">Đánh giá</div>

                                <Empty description="Chưa có đánh giá nào" />
                            </Col>
                        </Row>
                    ) : (
                        <Flex align="center" justify="center" style={{ minHeight: '68vh' }}>
                            <Spin />
                        </Flex>
                    )}
                </Card>
            </Col>

            <Col md={4} xs={24} style={{ padding: '0 8px' }}>
                <Card
                    title={
                        <div>
                            <h2 className="font-size-20">Lịch sử cập nhật</h2>
                        </div>
                    }
                >
                    <Empty description="Chưa có bản cập nhật nào" />
                </Card>
            </Col>
        </Row>
    );
}

export default Detail;
