import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Form, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { IconLock, IconMail, IconPhone, IconUser } from '@tabler/icons-react';

import config from '~/configs';
import Account from '../Account';
import { validateFullName } from './validate';
import { loginUserSuccess } from '~/redux/reducer/auth';
import { requestGetCurrentUser, requestRegisterUser } from '~/services/auth';
import { startLoadingGlobal, stopLoadingGlobal } from '~/redux/reducer/loading';

const { home, login, verify } = config.router;

function Register() {
    const [form] = Form.useForm();
    const { currentUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Thegioicode.vn - Register account';

        if (currentUser) {
            const fetch = async () => {
                dispatch(startLoadingGlobal());
                const result = await requestGetCurrentUser();

                dispatch(stopLoadingGlobal());
                if (result?.status === 200) {
                    dispatch(loginUserSuccess(result.data));
                    navigate(home);
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegisterUser = async (values) => {
        const { full_name, email, ...other } = values;

        const fullName = full_name.trim();
        const nameParts = fullName.split(/\s+/);
        const first_name = nameParts[0];
        const last_name = nameParts.slice(1).join(' ');

        const data = {
            first_name,
            last_name,
            email: email.toLowerCase(),
            ...other,
        };

        dispatch(startLoadingGlobal());
        const result = await requestRegisterUser(data);

        dispatch(stopLoadingGlobal());
        if (result?.status === 200) {
            navigate(`${verify}?email=${data.email}`);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Account>
            <h3 className="mb-1 font-weight-bold font-size-30">Đăng ký</h3>
            <p className="mb-6 text-center">Nhập thông tin chi tiết của bạn bên dưới để tạo tài khoản và bắt đầu trải nghiệm.</p>
            <Form form={form} name="horizontal_login" onFinish={handleRegisterUser} initialValues={{ phone_number: '' }}>
                <Form.Item
                    name="full_name"
                    rules={[
                        {
                            validator: validateFullName,
                        },
                        {
                            pattern:
                                /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơưĂĨŨƠưĂỊỸƠỚỐỨỀễếệọẠảấầẩẫậắằẳẵặẹẻẽềểễệỉịọỏốồổỗộớờởỡợụủứừửữựỳỵỷỹ\s]+$/,
                            message: 'Họ và tên không hợp lệ',
                        },
                    ]}
                >
                    <Input size="large" prefix={<IconUser />} placeholder="Họ và tên*" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập email',
                        },
                        {
                            type: 'email',
                            message: 'Email không đúng định dạng',
                        },
                    ]}
                >
                    <Input size="large" prefix={<IconMail />} placeholder="Email*" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập mật khẩu',
                        },
                        {
                            pattern: /^\S{6,20}$/,
                            message: 'Mật khẩu không hợp lệ',
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        prefix={<IconLock />}
                        placeholder="Mật khẩu*"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    name="phone_number"
                    rules={[
                        {
                            pattern: /^0[0-9]{9}$/,
                            message: 'Số điện thoại không hợp lệ',
                        },
                    ]}
                >
                    <Input size="large" prefix={<IconPhone />} placeholder="Số điện thoại" />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }}>
                            ĐĂNG KÝ
                        </Button>
                    )}
                </Form.Item>
            </Form>

            <div className="text-center mt-4">
                Bạn đã có tài khoản? <Link to={login}>Đăng nhập</Link>
            </div>
            <div className="mt-6 w-max-500 w-full">
                <div className="link-color text-center font-size-14">
                    Bằng việc nhấn nút ĐĂNG KÝ, Bạn đã đồng ý với{' '}
                    <Link target="_blank" to="/dieu-khoan-su-dung">
                        điều khoảng sử dụng dịch vụ{' '}
                    </Link>
                    và{' '}
                    <Link target="_blank" to="/chinh-sach-bao-mat">
                        chính sách bảo mật{' '}
                    </Link>
                    của chúng tôi.
                </div>
            </div>
        </Account>
    );
}

export default Register;
