import request from '~/utils';

export const requestGetServiceUseds = async () => {
    try {
        const res = await request.get('/my/services/in-use');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestChangeAutoRenewServiceUsed = async (id) => {
    try {
        const res = await request.get(`/my/services/auto-renew/${id}`);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestRenewServiceUsed = async (data) => {
    try {
        const res = await request.post('/my/services/renew', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
