import moment from 'moment';
import { useDispatch } from 'react-redux';
import { IconCopy } from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Flex, message, notification, Spin, Table, Tag, Tooltip } from 'antd';

import config from '~/configs';
import Webhook from './Webhook';
import Account from '../Account';
import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutUserSuccess } from '~/redux/reducer/auth';
import { requestGetApikeyUser } from '~/services/account';

const { login } = config.router;

function ApiKey() {
    const [userKey, setUserKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [serviceKeys, setServiceKeys] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    useEffect(() => {
        document.title = 'Thegioicode.vn - Account ApiKeys';

        const fetch = async () => {
            setLoading(true);
            const result = await requestGetApikeyUser();

            setLoading(false);
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${login}?redirect=${pathname}`);
            } else if (result?.status === 200) {
                setServiceKeys(result.data);
                setUserKey(result.user_key);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickCopy = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                message.success('Đã sao chép vào keyboard');
            })
            .catch((err) => {
                message.error(`Lỗi sao chép ${err}`);
            });
    };

    const onExpand = (record) => {
        const keys = expandedRowKeys.includes(record.key)
            ? expandedRowKeys.filter((k) => k !== record.key)
            : [...expandedRowKeys, record.key];
        setExpandedRowKeys(keys);
    };

    const defaultExpandable = {
        expandedRowRender: (record) => <Webhook webhooks={record.webhooks} api_key={record.api_key} />,
        expandedRowKeys,
        onExpand: (_, record) => onExpand(record),
        expandIcon: () => null,
    };

    const columns = [
        {
            title: 'Loại',
            dataIndex: 'category',
            key: 'category',
            render: (category) => <div style={{ whiteSpace: 'nowrap' }}>{category}</div>,
        },
        {
            title: 'Key',
            dataIndex: 'api_key',
            key: 'api_key',
            render: (api_key) => (
                <Flex align="center">
                    <Tooltip title="API key dịch vụ đang dùng">
                        <span className="mr-2">{api_key}</span>
                    </Tooltip>
                    <Tooltip title="Sao chép">
                        <IconCopy className="cursor-pointer" size={18} stroke={1.5} onClick={() => handleClickCopy(api_key)} />
                    </Tooltip>
                </Flex>
            ),
        },
        {
            title: 'Lần dùng',
            dataIndex: 'use',
            key: 'use',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let color = 'green';
                let message = 'Hoạt động';

                if (!status) {
                    color = 'red';
                    message = 'Tạm khoá';
                }

                return <Tag color={color}>{message}</Tag>;
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => <Fragment>{moment(created_at).format('DD-MM-YYYY HH:mm:ss')}</Fragment>,
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (data, record) => (
                <Button size="small" type="primary" onClick={() => record.onExpand(record)}>
                    Cấu hình
                </Button>
            ),
        },
    ];

    return (
        <Account>
            <div className="w-full">
                <h3 className="font-bold font-size-20 border-bottom pb-2 mb-5">Danh sách api keys</h3>

                {loading ? (
                    <Flex align="center" justify="center" style={{ minHeight: '40vh' }}>
                        <Spin />
                    </Flex>
                ) : (
                    <Card>
                        <div className="mb-5">
                            <label className="font-bold mb-0 d-inline-block mr-5">Api key user:</label>

                            <div className="d-inline-flex align-items-center">
                                <Tooltip title="API key tài khoản của bạn">
                                    <span className="mr-2">{userKey}</span>
                                </Tooltip>
                                <Tooltip title="Sao chép">
                                    <IconCopy className="cursor-pointer" size={18} stroke={1.5} onClick={() => handleClickCopy(userKey)} />
                                </Tooltip>
                            </div>
                        </div>

                        <div className="d-flex align-items-center gap-1 font-bold mb-0">
                            <span>Api key services:</span>
                            <IconQuestion
                                width={12}
                                height={12}
                                className="text-subtitle"
                                title="Nếu không thấy api keys vui lòng ấn vào dịch vụ cần sử dụng để kích hoạt."
                            />
                        </div>
                        <Table
                            columns={columns}
                            dataSource={serviceKeys.map((serviceKey) => ({ ...serviceKey, onExpand }))}
                            pagination={false}
                            expandable={defaultExpandable}
                        />
                    </Card>
                )}
            </div>
        </Account>
    );
}

export default ApiKey;
