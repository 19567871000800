import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconArrowNarrowRight, IconBrandCodepen, IconCloudLock, IconWorldWww } from '@tabler/icons-react';
import { Avatar, Button, Flex, Modal, notification, Radio, Slider, Space, Spin, Switch, Table, Tabs, Tag, Tooltip } from 'antd';

import config from '~/configs';
import imageWallet from '~/assets/image/wallet.png';
import { logoutUserSuccess } from '~/redux/reducer/auth';
import IconReNewDollar from '~/assets/icon/IconReNewDollar';
import { calculateDaysLeft, convertCurrency, generateCateString } from '~/configs';
import { requestChangeAutoRenewServiceUsed, requestRenewServiceUsed } from '~/services/service';

const { login, home, sources: sourcePath } = config.router;

const formatterValue = (value) => `${value}`;

const parserUnitCycle = (cycles, cycles_unit) => {
    let unit = '';

    switch (cycles_unit) {
        case 'minutes':
            unit = cycles + ' phút';
            break;
        case 'hours':
            unit = cycles + ' giờ';
            break;
        case 'days':
            unit = cycles + ' ngày';
            break;
        case 'months':
            unit = cycles + ' tháng';
            break;
        case 'years':
            unit = cycles + ' năm';
            break;
        case 'forever':
            unit = 'Vĩnh viễn';
            break;
        default:
            throw new Error('Đơn vị chu kỳ không hợp lệ');
    }

    return unit;
};

function ServiceUsed({ data }) {
    const [sources, setSources] = useState([]);
    const [domains, setDomains] = useState([]);
    const [cloudVps, setCloudVps] = useState([]);

    const [dataAutoRenew, setDataAutoRenew] = useState(null);
    const [openConfirmAutoRenew, setOpenConfirmAutoRenew] = useState(false);

    const [loadingRenew, setLoadingRenew] = useState(false);
    const [dataRenew, setDataRenew] = useState(null);
    const [totalPriceRenew, setTotalPriceRenew] = useState(0);
    const [serviceRenewTime, setServiceRenewTime] = useState(1);
    const [openConfirmRenew, setOpenConfirmRenew] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        const dataDomain = data.filter((source) => source.service_type === 'domain');
        const dataSources = data.filter((source) => source.service_type === 'source');
        const dataCloudVps = data.filter((source) => source.service_type === 'cloud_vps');

        setDomains(dataDomain);
        setSources(dataSources);
        setCloudVps(dataCloudVps);
    }, [data]);

    useEffect(() => {});

    const handleChangeRenewTime = (e) => {
        setServiceRenewTime(e);
        setTotalPriceRenew(dataRenew.price * e);
    };

    const handleAutoRenewServiceUsed = async () => {
        if (!dataAutoRenew.id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn dịch vụ muốn Bật/Tắt',
            });
        }

        const result = await requestChangeAutoRenewServiceUsed(dataAutoRenew.id);

        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${login}?redirect=${pathname}`);
        } else if (result?.status === 200) {
            if (dataAutoRenew.service_type === 'source') {
                const cloneSources = [...sources];
                const indexSource = cloneSources.findIndex((source) => source.id === dataAutoRenew.id);
                cloneSources[indexSource].auto_renew = !cloneSources[indexSource].auto_renew;
                setSources(cloneSources);
            }
            if (dataAutoRenew.service_type === 'cloud_vps') {
                const cloneCloudVps = [...cloudVps];
                const indexCloudVps = cloneCloudVps.findIndex((cloud) => cloud.id === dataAutoRenew.id);
                cloneCloudVps[indexCloudVps].auto_renew = !cloneCloudVps[indexCloudVps].auto_renew;
                setCloudVps(cloneCloudVps);
            }
            if (dataAutoRenew.service_type === 'domain') {
                const cloneDomains = [...domains];
                const indexDomain = cloneDomains.findIndex((domain) => domain.id === dataAutoRenew.id);
                cloneDomains[indexDomain].auto_renew = !cloneDomains[indexDomain].auto_renew;
                setCloudVps(cloneDomains);
            }
            setDataAutoRenew(null);
            setOpenConfirmAutoRenew(false);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    // Renew
    const handleReNewServiceUsed = async () => {
        if (!dataRenew.id || !serviceRenewTime) {
            return notification.error({
                message: 'Thông báo',
                description: 'Vui lòng chọn dịch vụ và thời gian gia hạn',
            });
        }

        if (currentUser?.wallet.total_balance < totalPriceRenew) {
            return notification.error({
                message: 'Thông báo',
                description: 'Bạn không đủ số dư để thực hiện gia hạn',
            });
        }

        setLoadingRenew(true);
        const data = {
            service_id: dataRenew.id.toString(),
            cycles: serviceRenewTime,
        };

        const result = await requestRenewServiceUsed(data);

        setLoadingRenew(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${login}?redirect=${pathname}`);
        } else if (result?.status === 200) {
            setTotalPriceRenew(0);
            setServiceRenewTime(1);
            setOpenConfirmRenew(false);

            if (dataRenew.service_type === 'source') {
                const cloneSources = [...sources];

                const indexSource = cloneSources.findIndex((source) => source.id === dataRenew.id);
                if (indexSource === -1) {
                    return;
                }

                cloneSources[indexSource].expired_at = moment(result.data).format('YYYY-MM-DD HH:mm:ss');
                setSources(cloneSources);
            }
            if (dataRenew.service_type === 'cloud_vps') {
                const cloneCloudVps = [...cloudVps];

                const indexCloudVps = cloneCloudVps.findIndex((cloud) => cloud.id === dataRenew.id);
                if (indexCloudVps === -1) {
                    return;
                }

                cloneCloudVps[indexCloudVps].expired_at = moment(result.data).format('YYYY-MM-DD HH:mm:ss');
                setCloudVps(cloneCloudVps);
            }
            if (dataRenew.service_type === 'domain') {
                const cloneDomains = [...domains];

                const indexDomain = cloneDomains.findIndex((domain) => domain.id === dataRenew.id);
                if (indexDomain === -1) {
                    return;
                }

                cloneDomains[indexDomain].expired_at = moment(result.data).format('YYYY-MM-DD HH:mm:ss');
                setCloudVps(cloneDomains);
            }

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'Tên',
            key: 'name',
            render: (data) => (
                <Fragment>
                    <b>{data.display_name}</b>
                    <br />
                    {data.service && (
                        <Link to={`${sourcePath}/detail/${data.service.slug_url}`} title={data.service.title}>
                            {generateCateString(data.service.title, 38)}
                        </Link>
                    )}
                </Fragment>
            ),
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            key: 'price',
            render: (price) => <span className="text-success">{convertCurrency(price)}</span>,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let title = '';
                let color = '#ff4d4f';
                if (status === 'active') {
                    color = '#52c41a';
                    title = 'Hoạt động';
                }
                if (status === 'inactive') {
                    color = '#ffa940';
                    title = 'Tạm tắt';
                }
                if (status === 'pending') {
                    color = '#ffc107';
                    title = 'Đang chờ';
                }
                if (status === 'expired') {
                    color = '#ff4d4f';
                    title = 'Hết hạn';
                }

                return <Tag color={color}>{title}</Tag>;
            },
        },
        {
            title: 'Tự động gia hạn',
            key: 'auto_renew',
            render: (data) => (
                <Switch
                    checkedChildren="Bật"
                    unCheckedChildren="Tắt"
                    value={data.auto_renew}
                    disabled={!data.expired_at}
                    onClick={() => {
                        setDataAutoRenew(data);
                        setOpenConfirmAutoRenew(true);
                    }}
                />
            ),
        },
        {
            title: 'Thời gian',
            key: 'date',
            render: (date) => (
                <Fragment>
                    <Tooltip title="Ngày đăng ký">
                        <span>{moment(date.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    </Tooltip>
                    <br />
                    {date.expired_at && (
                        <Fragment>
                            <Tooltip title="Ngày hết hạn">
                                <span>{moment(date.expired_at).format('YYYY-MM-DD HH:mm')}</span>
                            </Tooltip>
                            <br />
                            (Còn{' '}
                            <b className={calculateDaysLeft(date.expired_at) < 8 ? 'text-danger' : ''}>
                                {calculateDaysLeft(date.expired_at)}
                            </b>{' '}
                            ngày)
                        </Fragment>
                    )}
                </Fragment>
            ),
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (data) => (
                <Space>
                    {data.expired_at && (
                        <Tooltip title="Gia hạn">
                            <Button
                                className="box-center"
                                type="primary"
                                onClick={() => {
                                    setDataRenew(data);
                                    setOpenConfirmRenew(true);
                                    setTotalPriceRenew(data.price);
                                }}
                            >
                                <IconReNewDollar width={18} height={18} />
                            </Button>
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const items = [
        {
            label: (
                <span className="box-align-center gap-2">
                    <Avatar
                        style={{ width: 20, height: 20, lineHeight: 20, fontSize: 18, background: '#096eff', padding: 1 }}
                        icon={<IconBrandCodepen />}
                    />
                    Mã nguồn ({sources.length})
                </span>
            ),
            key: '1',
            children: (
                <Fragment>
                    <Table columns={columns} dataSource={sources.map((source) => ({ key: source.id, ...source }))} pagination={false} />

                    <div className="p-2 d-flex">
                        <Link className="hover-underline box-center hover-blue font-size-15" to={home}>
                            <span className="mr-1">Xem thêm</span>
                            <IconArrowNarrowRight width={20} height={20} />
                        </Link>
                    </div>
                </Fragment>
            ),
        },
        {
            label: (
                <span className="box-align-center gap-2">
                    <Avatar
                        style={{ width: 20, height: 20, lineHeight: 20, fontSize: 18, background: '#096eff', padding: 1 }}
                        icon={<IconCloudLock />}
                    />
                    Cloud VPS ({cloudVps.length})
                </span>
            ),
            key: '2',
            children: (
                <Fragment>
                    <Table columns={columns} dataSource={cloudVps.map((cloud) => ({ key: cloud.id, ...cloud }))} pagination={false} />

                    <div className="p-2 d-flex">
                        <Link className="hover-underline box-center hover-blue font-size-15" to={home}>
                            <span className="mr-1">Xem thêm</span>
                            <IconArrowNarrowRight />
                        </Link>
                    </div>
                </Fragment>
            ),
        },
        {
            label: (
                <span className="box-align-center gap-2">
                    <Avatar
                        style={{ width: 20, height: 20, lineHeight: 20, fontSize: 18, background: '#096eff', padding: 1 }}
                        icon={<IconWorldWww />}
                    />
                    Domain ({domains.length})
                </span>
            ),
            key: '3',
            children: (
                <Fragment>
                    <Table columns={columns} dataSource={domains.map((domain) => ({ key: domain.id, ...domain }))} pagination={false} />

                    <div className="p-2 d-flex">
                        <Link className="hover-underline box-center hover-blue font-size-15" to={home}>
                            <span className="mr-1">Xem thêm</span>
                            <IconArrowNarrowRight />
                        </Link>
                    </div>
                </Fragment>
            ),
        },
    ];

    return (
        <div className="mb-8">
            <h2 className="font-semibold font-size-20 mb-4">Các dịch vụ đang sử dụng</h2>

            <Tabs type="card" items={items} className="home_tabs" />

            {openConfirmAutoRenew && (
                <Modal
                    centered
                    closable={false}
                    open={openConfirmAutoRenew}
                    onOk={handleAutoRenewServiceUsed}
                    onCancel={() => setOpenConfirmAutoRenew(false)}
                    width={450}
                    okText="Xác nhận"
                    cancelText="Hủy"
                    title="Tự động gia hạn"
                >
                    <p>Bạn có muốn Bật/Tắt tự động gia hạn không?</p>
                </Modal>
            )}

            {openConfirmRenew && dataRenew && (
                <Modal
                    centered
                    closable={false}
                    maskClosable={false}
                    open={openConfirmRenew}
                    onOk={handleReNewServiceUsed}
                    onCancel={() => {
                        setDataRenew(null);
                        setTotalPriceRenew(0);
                        setServiceRenewTime(1);
                        setOpenConfirmRenew(false);
                    }}
                    width={500}
                    okText={<Fragment>{loadingRenew ? <Spin size="small" /> : 'Xác nhận'}</Fragment>}
                    cancelText="Hủy"
                    okButtonProps={{ disabled: loadingRenew }}
                    cancelButtonProps={{ disabled: loadingRenew }}
                >
                    <div>
                        <h2 className="font-size-16">Dịch vụ cần gia hạn:</h2>
                        <p className="mb-5 mt-2 font-size-15">{dataRenew.display_name}</p>
                    </div>
                    <div>
                        <h2 className="font-size-16">Chọn thời gian gia hạn?</h2>
                        <div className="my-5">
                            <Slider
                                tooltip={{
                                    formatter: (e) => formatterValue(parserUnitCycle(e, dataRenew.cycles_unit)),
                                }}
                                min={1}
                                max={12}
                                value={serviceRenewTime}
                                onChange={(e) => handleChangeRenewTime(e)}
                            />
                        </div>
                    </div>

                    <div>
                        <Flex className="flex-wrap gap-3 pb-2">
                            <h3 className="font-size-16 font-bold mb-0">Phương thức thanh toán</h3>
                        </Flex>

                        <Radio.Group value={1} className="w-full">
                            <Radio value={1} className="border-antd billing-payment-method_item">
                                <Flex align="center" className="gap-2">
                                    <Avatar
                                        shape="square"
                                        src={imageWallet}
                                        style={{ fontSize: 18, width: 35, height: 35, lineHeight: 35 }}
                                    />
                                    <div>
                                        <span className="mr-2">Ví Thegioicode</span>
                                        <p className="text-primary mb-0 line-height-15 font-size-13">
                                            Số dư: {convertCurrency(currentUser?.wallet.total_balance)}
                                        </p>
                                    </div>
                                </Flex>
                            </Radio>
                        </Radio.Group>
                        <Flex justify="space-between" className="border-bottom py-2 mt-3">
                            <span className="font-bold">Tổng thanh toán</span>
                            <span className="font-bold font-size-18 text-primary">{convertCurrency(totalPriceRenew)}</span>
                        </Flex>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default ServiceUsed;
