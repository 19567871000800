import { message } from 'antd';
import router from './routes';

// Hàm chuyển đổi số tiền thành chuỗi
export const convertCurrency = (number) => {
    if (number == null || number === undefined) {
        return 'Null';
    }

    const amount = Number(number);
    let check = typeof amount === 'number' ? true : false;

    return check ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'đ' : 'Null';
};

export const generateCateString = (str, maxLength = 10) => {
    if (!str) {
        return 'Null';
    }
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    }
    return str;
};

// Tính ngày hết hạn
export const calculateDaysLeft = (expirationDate) => {
    const currentDate = new Date();
    const expireDate = new Date(expirationDate);

    const timeDifference = expireDate - currentDate;
    const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysLeft;
};

export function checkImage(urlImage) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            resolve(urlImage);
        };

        img.onerror = function () {
            reject(null);
        };

        img.src = urlImage;
    });
}

export const convertTimeUnit = (value, unit) => {
    const unitMap = {
        minutes: 'Phút',
        hours: 'Giờ',
        days: 'Ngày',
        months: 'Tháng',
        years: 'Năm',
        forever: 'Vĩnh viễn',
    };

    if (!unitMap[unit]) {
        return message.error(`Đơn vị thời gian ${unit} không hợp lệ`);
    }

    if (unit === 'forever') {
        return unitMap[unit];
    }

    return `${value} ${unitMap[unit]}`;
};

export const shortNumberConversion = (number) => {
    if (number >= 1000000000) {
        // Hàng tỷ (Billion)
        return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number >= 1000000) {
        // Hàng triệu (Million)
        return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number >= 10000) {
        // Hàng chục nghìn (Thousand)
        return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }

    // Trả về số gốc nếu nhỏ hơn 10,000
    return number.toString();
};

const config = {
    router,
};

export default config;
