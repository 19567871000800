import { isMobile } from 'react-device-detect';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Drawer, Flex, Layout, Menu, Spin, theme, notification, Divider, Tooltip, Badge } from 'antd';
import {
    IconApiApp,
    IconAlignLeft,
    IconBorderAll,
    IconHomeDollar,
    IconAlignRight,
    IconBrandCodepen,
    IconShoppingCart,
    IconClipboardText,
} from '@tabler/icons-react';

import './DefaultLayout.css';
import NewFeed from '../components/NewsFeed';
import config, { convertCurrency } from '~/configs';
import ProfileMenu from '../components/ProfileMenu';
import Notification from '../components/Notification';
import { requestGetCurrentUser } from '~/services/auth';
import { dispatchConfigApps } from '~/redux/reducer/app';
import { requestGetConfigApps, requestGetNewsFeeds } from '~/services/app';
import { loginUserSuccess, logoutUserSuccess } from '~/redux/reducer/auth';

const { Content, Sider, Header } = Layout;
const { home, login, services, billing, source_free, source_for_sale, source_pay_fee, docs, carts, affiliate } = config.router;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const items = [
    getItem(<Link to={home}>Trang chủ</Link>, home, <IconBorderAll />),
    getItem('Mã nguồn', 'sub-2', <IconBrandCodepen />, [
        getItem(<Link to={source_pay_fee}>Trả phí</Link>, source_pay_fee),
        getItem(<Link to={source_free}>Miễn phí</Link>, source_free),
        getItem(<Link to={source_for_sale}>Đăng bán</Link>, source_for_sale),
    ]),
    getItem(<Link to={services}>API</Link>, services, <IconApiApp />),
    getItem(<Link to={billing}>Thanh toán</Link>, billing, <IconHomeDollar />),
    getItem(<Link to={docs}>Docs</Link>, docs, <IconClipboardText />),
];

function DefaultLayout({ children }) {
    const { pathname } = useLocation();
    const [current, setCurrent] = useState(() => pathname);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [newsFeeds, setNewsFeeds] = useState([]);
    const [loadingApp, setLoadingApp] = useState(false);
    const [collapsed, setCollapsed] = useState(isMobile);
    const [openNewFeed, setOpenNewFeed] = useState(false);
    const [websiteStatus, setWebsiteStatus] = useState(true);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { configs } = useSelector((state) => state.apps);
    const { currentUser } = useSelector((state) => state.auth);
    const [lastBalance, setLastBalance] = useState(currentUser?.wallet?.total_balance);

    const onClickMenu = (e) => {
        setCurrent(e.key);

        if (isMobile) {
            setOpen(false);
        }
    };

    useEffect(() => {
        const fetch = async () => {
            const result = await requestGetConfigApps();

            if (result.status === 200) {
                const updateFavicon = (iconUrl) => {
                    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href = iconUrl;
                    document.getElementsByTagName('head')[0].appendChild(link);
                };

                updateFavicon(result.data.favicon_url);
                dispatch(dispatchConfigApps(result.data));

                if (pathname === home) {
                    const resultNewsFeed = await requestGetNewsFeeds();
                    if (resultNewsFeed.status === 200) {
                        setNewsFeeds(resultNewsFeed.data);
                    }
                }
            } else {
                setLoadingApp(true);
                setWebsiteStatus(false);
            }
        };
        fetch();

        if (pathname === home) {
            setOpenNewFeed(true);
            document.body.classList.add('open-new-feed');
        }

        // Ngăn chặn chuột phải
        const handleContextMenu = (event) => {
            notification.error({
                message: 'Thông báo',
                description: 'Dừng lại việc truy cập công cụ dành cho nhà phát triển',
            });

            event.preventDefault();
        };

        // Ngăn chặn F12 và Ctrl + Shift + I
        const handleKeyDown = (event) => {
            if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Dừng lại việc truy cập công cụ dành cho nhà phát triển',
                });

                setTimeout(() => {
                    window.location.href = 'https://zalo.me/0706661234';
                }, 600);

                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('contextmenu', handleContextMenu);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (configs && configs.website_status) {
            const fetch = async () => {
                try {
                    const result = await requestGetCurrentUser();

                    if (result.status === 200) {
                        if (lastBalance && lastBalance !== result.data.wallet.total_balance) {
                            const amount = result.data.wallet.total_balance - lastBalance;

                            const type = amount > 0 ? 'success' : 'error';
                            const className = amount > 0 ? 'text-success' : 'text-danger';
                            const message = amount > 0 ? 'Nạp tiền thành công' : 'Tài khoản trừ tiền';

                            notification[type]({
                                message: 'Thông báo',
                                description: (
                                    <Fragment>
                                        <span className="mr-1">{message}</span>
                                        <span className={className}>
                                            {amount > 0 && '+'}
                                            {convertCurrency(amount)}
                                        </span>
                                    </Fragment>
                                ),
                            });
                            setLastBalance(result.data.wallet.total_balance);
                        }

                        dispatch(loginUserSuccess(result.data));
                    } else {
                        dispatch(logoutUserSuccess());
                        navigate(`${login}?redirect=${pathname}`);

                        notification.error({
                            message: 'Thông báo',
                            description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                        });
                    }
                } catch (error) {
                    notification.error({
                        message: 'Thông báo',
                        description: 'Lỗi hệ thống vui lòng thử lại sau',
                    });
                } finally {
                    setLoading(false);
                }
            };
            fetch();

            const intervalId = setInterval(fetch, 8000);

            return () => clearInterval(intervalId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastBalance, configs]);

    const toggleCollapsed = () => {
        if (isMobile) {
            setOpen(!open);
        } else {
            if (collapsed) {
                setCollapsed(false);
                document.querySelector('.default-content').classList.remove('active');
            } else {
                setCollapsed(true);
                document.querySelector('.default-content').classList.add('active');
            }
        }
    };

    if (loadingApp && (!websiteStatus || !configs?.website_status)) {
        return (
            <Flex align="center" justify="center" style={{ height: '90vh' }}>
                <div className="text-center">
                    <h2 className="font-size-15">Website bảo trì vui lòng truy cập lại sau!</h2>
                    <p className="text-subtitle font-size-14 mt-2">Xin lỗi quý khách vì sự bất tiện này.</p>
                </div>
            </Flex>
        );
    }

    return (
        <Fragment>
            {loading ? (
                <Flex align="center" justify="center" style={{ height: '70vh' }}>
                    <Spin />
                </Flex>
            ) : (
                <Layout>
                    {openNewFeed && newsFeeds.length > 0 && pathname === home && <NewFeed data={newsFeeds} onHide={setOpenNewFeed} />}

                    <Header
                        className="default__header"
                        style={{ background: colorBgContainer, width: '100%', paddingLeft: 10, paddingRight: 20 }}
                    >
                        <div className="default__header-hr"></div>
                        <Flex align="center" justify="space-between" style={{ height: '100%' }}>
                            <div className="default__header-logo">
                                <span className="default-btn" onClick={toggleCollapsed}>
                                    {collapsed ? <IconAlignLeft className="text-subtitle" /> : <IconAlignRight className="text-subtitle" />}
                                </span>
                                <Link to={home}>
                                    <div className="header__logo-pc">
                                        <img src={configs.website_logo_url} alt="Trang chủ" className="header__logo-mobile" />
                                    </div>
                                </Link>
                            </div>

                            <Flex align="center" className="h-full">
                                <Notification currentUser={currentUser} />

                                <div className="default__header-item">
                                    <Link to={carts} className="text-black">
                                        <Tooltip title={isMobile ? '' : 'Giỏ hàng'}>
                                            <Badge
                                                count={currentUser?.carts_count}
                                                overflowCount={9}
                                                size="small"
                                                offset={[0, 6]}
                                                className="mt-5"
                                            >
                                                <Avatar
                                                    className="text-warning box-center box-header-icon"
                                                    icon={<IconShoppingCart size={20} />}
                                                    style={{ width: 35, height: 35, lineHeight: 35 }}
                                                />
                                            </Badge>
                                        </Tooltip>
                                    </Link>
                                </div>

                                <Divider type="vertical" className="ml-3 mr-0" style={{ height: 20 }} />

                                <div className="default__header-item d-none-tablet">
                                    <Link to={affiliate} className="text-black">
                                        <div className="box-header-text">
                                            <Tooltip title="Số dư có thể rút">
                                                <div>
                                                    <div className="font-size-10 font-semibold line-height-12">Số dư chính</div>
                                                    <div className="font-semibold line-height-14 font-size-13 text-warning text-center">
                                                        {convertCurrency(currentUser?.wallet.main_balance)}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </Link>
                                </div>

                                <div className="default__header-item d-none-tablet">
                                    <Link to={billing} className="text-black">
                                        <div className="box-header-text">
                                            <div>
                                                <div className="font-size-10 font-semibold line-height-12">Số dư hiện tại</div>
                                                <div className="font-semibold line-height-14 font-size-13 text-primary text-center">
                                                    {convertCurrency(currentUser?.wallet.total_balance)}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <ProfileMenu />
                            </Flex>
                        </Flex>
                    </Header>
                    <Layout className="default_layout-layout">
                        {isMobile ? (
                            <Drawer
                                title={
                                    <div>
                                        <img src={configs.website_logo_url} alt="Trang chủ" className="header__logo-mobile" />
                                    </div>
                                }
                                open={open}
                                placement="left"
                                onClose={() => setOpen(false)}
                                width={'70%'}
                                style={{ maxWidth: 320, minWidth: 270 }}
                                className="header-drawer"
                            >
                                <Menu
                                    selectedKeys={[current]}
                                    onClick={(e) => onClickMenu(e)}
                                    defaultOpenKeys={['1']}
                                    mode="inline"
                                    className="menu-sidebar"
                                    items={items}
                                />
                            </Drawer>
                        ) : (
                            <Sider
                                trigger={null}
                                collapsible
                                collapsed={collapsed}
                                theme="line"
                                className="default-sider"
                                width={230}
                                style={{
                                    background: colorBgContainer,
                                    minHeight: 'calc(100vh - 116px)',
                                    position: 'fixed',
                                }}
                            >
                                <div className="default-menu">MENU</div>
                                <Menu
                                    selectedKeys={[current]}
                                    onClick={(e) => setCurrent(e.key)}
                                    defaultOpenKeys={['1']}
                                    mode="inline"
                                    items={items}
                                    className="menu-sidebar"
                                    style={{
                                        overflowX: 'hidden',
                                        overflowY: 'auto',
                                        height: 'calc(100vh - 120px)',
                                    }}
                                />
                            </Sider>
                        )}
                        <Content className="default-content">{children}</Content>
                    </Layout>
                </Layout>
            )}
        </Fragment>
    );
}

export default DefaultLayout;
