import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrumb, Button, Card, Col, Empty, Flex, Pagination, Row, Spin, notification } from 'antd';

import config from '~/configs';
import SourceItem from '../SourceItem';
import { requestGetSources } from '~/services/source';
import { logoutUserSuccess } from '~/redux/reducer/auth';

const { login, home } = config.router;

function Sources({ category }) {
    const [pages, setPages] = useState(1);
    const [sources, setSources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(searchParams.get('page') || 1);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        document.title = `Thegioicode.vn - Mã nguồn ${category === 'free' ? 'miễn phí' : 'trả phí'}`;

        const fetch = async () => {
            setLoading(true);
            const result = await requestGetSources(page, category);

            setLoading(false);
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${login}?redirect=${pathname}`);
            } else if (result?.status === 200) {
                setPages(result.pages);
                setSources(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, category]);

    return (
        <Row style={{ rowGap: 16 }}>
            <Col span={24}>
                <Flex className="gap-2 pl-2">
                    <Button size="small" className="box-center" onClick={() => navigate(home)}>
                        <IconArrowLeft size={18} />
                    </Button>
                    <Breadcrumb
                        items={[
                            {
                                title: <Link to={home}>Trang chủ</Link>,
                            },

                            {
                                title: `Mã nguồn ${category === 'free' ? 'miễn phí' : 'trả phí'}`,
                            },
                        ]}
                    />
                </Flex>
            </Col>
            <Col md={20} xs={24} style={{ padding: '0 8px' }}>
                <Card style={{ minHeight: 'calc(-148px + 100vh)' }}>
                    <div className="mb-20 text-center source-header">
                        <h2 className="font-max font-size-24 mb-4">PHẦN MỀM WEBSITE</h2>
                        <span className="text-subtitle text-uppercase">
                            CÁC PHẦN MỀM CÓ SẴN GIÚP TIẾT KIỆM CHI PHÍ VÀ RÚT NGẮN THỜI GIAN XÂY DỰNG
                        </span>
                    </div>

                    {loading ? (
                        <Flex align="center" justify="center" style={{ minHeight: '68vh' }}>
                            <Spin />
                        </Flex>
                    ) : (
                        <Row style={{ marginLeft: -12, marginRight: -12, rowGap: 24 }}>
                            <Col md={6} xs={24} style={{ paddingLeft: 12, paddingRight: 12 }}>
                                <SourceItem
                                    href="https://zalo.me/0706661234"
                                    target="_blank"
                                    code="000"
                                    title="Thiết kế website theo yêu cầu đầy đủ chức năng"
                                    created_at="29-1-2019"
                                    views={4812}
                                    purchases={3772}
                                    price="Liên hệ admin"
                                    old_price={500000}
                                    btn_text="Đặt hàng"
                                />
                            </Col>

                            {sources.map((source, index) => (
                                <Col md={6} xs={24} style={{ paddingLeft: 12, paddingRight: 12 }} key={index}>
                                    <SourceItem
                                        href={`/sources/detail/${source.slug_url}`}
                                        code={source.code}
                                        title={source.title}
                                        created_at={moment(source.created_at).format('DD-MM-YYYY')}
                                        views={source.views}
                                        purchases={source.purchases}
                                        price={source.price}
                                        old_price={source.old_price}
                                        image_url={source.image_url}
                                        btn_text="Xem chi tiết"
                                        usage_time={source.usage_time}
                                    />
                                </Col>
                            ))}
                        </Row>
                    )}

                    {Number(pages) > 1 && (
                        <Flex justify="end" style={{ margin: '20px 0 10px 0' }}>
                            <Pagination
                                current={page || 1}
                                pageSize={20}
                                total={Number(pages) * 20}
                                onChange={(page) => {
                                    setPage(page);
                                    setSearchParams({ page });
                                }}
                            />
                        </Flex>
                    )}
                </Card>
            </Col>

            <Col md={4} xs={24} style={{ padding: '0 8px' }}>
                <Card
                    title={
                        <div>
                            <h2 className="font-size-20">Mô-đun nâng cao</h2>
                        </div>
                    }
                >
                    <Empty description="Không có dữ liệu" />
                </Card>
            </Col>
        </Row>
    );
}

export default Sources;
