import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconArrowLeft, IconCheck, IconTicket, IconTrash } from '@tabler/icons-react';
import { Avatar, Breadcrumb, Button, Card, Col, Flex, Input, message, Modal, notification, Radio, Row, Select, Spin, Table } from 'antd';

import config, { convertCurrency } from '~/configs';
import imageWallet from '~/assets/image/wallet.png';
import imageEmptyCart from '~/assets/image/empty-cart.png';
import { loginUserSuccess, logoutUserSuccess } from '~/redux/reducer/auth';
import { requestClearCartsUser, requestGetCartsUser, requestCreateOrderCartUser } from '~/services/account';

const { Option } = Select;
const { home, login } = config.router;

const units = {
    forever: 'Vĩnh viễn',
    minutes: 'Phút',
    hours: 'Giờ',
    days: 'Ngày',
    months: 'Tháng',
    years: 'Năm',
};

// Tạo các tùy chọn cho các đơn vị thời gian khác
const generateOptions = (unit, value) => {
    if (unit === 'forever') {
        return [{ value: value, label: 'Vĩnh viễn' }];
    }

    let multiples = [];
    for (let i = 1; i <= 6; i += 2) {
        multiples.push({
            value: value * i,
            label: `${value * i} ${units[unit]}`,
        });
    }

    return multiples;
};

function Cart() {
    const [carts, setCarts] = useState([]);
    const [voucher, setVoucher] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        document.title = 'Thegioicode.vn - Carts';

        const fetch = async () => {
            setLoading(true);

            const result = await requestGetCartsUser();

            setLoading(false);
            if (result.status === 401 || result.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${login}?redirect=${pathname}`);
            } else if (result.status === 200) {
                const total = result.data.reduce((price, current) => {
                    return price + current.price * current.quantity;
                }, 0);

                setTotalPrice(total);
                setCarts(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectOption = (value) => {
        notification.error({ message: 'Thông báo', description: 'Chức năng đổi option đang được phát triển' });
    };

    const handleAddVoucher = () => {
        if (voucher) {
            notification.error({
                message: 'Thông báo',
                description: 'Không thể áp dụng chương trình này',
            });
        } else {
        }
    };

    const handleClearCart = async () => {
        const data = carts.map((data) => data.id);
        if (data.length < 1) {
            return notification.error({ message: 'Thông báo', description: 'Giỏ hàng trống không có gì để xoá' });
        }

        const result = await requestClearCartsUser(data);

        setModalVisible(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${login}?redirect=${pathname}`);
        } else if (result.status === 200) {
            const { carts_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ carts_count: 0, ...others }));

            setCarts([]);
            setTotalPrice(0);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleRemoveItemCart = async (data) => {
        if (!data) {
            return notification.error({ message: 'Thông báo', description: 'Chọn sản phẩm cần xoá khỏi giỏ hàng' });
        }

        const result = await requestClearCartsUser([data]);

        setModalVisible(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${login}?redirect=${pathname}`);
        } else if (result.status === 200) {
            const { carts_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ carts_count: carts_count - 1, ...others }));

            const cloneCarts = [...carts];

            const indexCart = cloneCarts.findIndex((item) => item.id === data);
            if (indexCart !== -1) {
                cloneCarts.splice(indexCart, 1);
                setCarts(cloneCarts);

                setTotalPrice(
                    cloneCarts.reduce((price, current) => {
                        return price + current.price * current.quantity;
                    }, 0),
                );

                notification.success({
                    message: 'Thông báo',
                    description: result.message,
                });
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy sản phẩm trong giỏ hàng',
                });
            }
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleCreateOrder = async () => {
        setLoadingPayment(true);
        const result = await requestCreateOrderCartUser();

        setLoadingPayment(false);
        if (result.status === 401 || result.status === 403) {
            dispatch(logoutUserSuccess());
            navigate(`${login}?redirect=${pathname}`);
        } else if (result.status === 200) {
            setCarts([]);
            setTotalPrice(0);

            const { carts_count, ...others } = currentUser;
            dispatch(loginUserSuccess({ carts_count: 0, ...others }));

            navigate(`/billing/orders/${result.data}`);
            message.success(result.message);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'Sản phẩm',
            dataIndex: 'product',
            key: 'product',
            render: (product) => <b>{product || 'Null'}</b>,
        },
        {
            title: 'Chu kỳ',
            dataIndex: 'usage_time',
            key: 'usage_time',
            render: (usage_time) => {
                const options = generateOptions(usage_time.unit, usage_time.value);

                return (
                    <Fragment>
                        <Select value={usage_time.value} style={{ width: 120 }} onChange={handleSelectOption}>
                            {options.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Fragment>
                );
            },
        },
        {
            title: 'Số tiền',
            key: 'total_price',
            render: (data) => (
                <Flex align="center" justify="space-between">
                    <div>{convertCurrency(data.price * data.quantity)}</div>
                    <Button
                        size="small"
                        className="box-center hover-red hover-border-red ml-2"
                        onClick={() => handleRemoveItemCart(data.id)}
                    >
                        <IconTrash size={16} />
                    </Button>
                </Flex>
            ),
        },
    ];

    return (
        <div className="container">
            <Row>
                <Col span={24}>
                    <Flex className="gap-2 mb-3 ml-2">
                        <Button size="small" className="box-center" onClick={() => navigate('/billing/orders')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            className="flex-1"
                            items={[
                                {
                                    title: <Link to={home}>Trang chủ</Link>,
                                },

                                {
                                    title: 'Giỏ hàng',
                                },
                            ]}
                        />
                    </Flex>
                </Col>

                {!loading ? (
                    <Col span={24}>
                        <Row style={{ rowGap: 16 }}>
                            <Col md={17} style={{ padding: '0 8px' }}>
                                <Card styles={{ body: { padding: '10px 18px 18px' } }}>
                                    {carts.length > 0 ? (
                                        <Fragment>
                                            <Flex align="center" justify="space-between">
                                                <h2 className="font-size-20 font-bold mb-0">Giỏ hàng ({carts.length})</h2>

                                                <Button
                                                    danger
                                                    type="link"
                                                    className="box-center gap-1"
                                                    onClick={() => setModalVisible(true)}
                                                >
                                                    <IconTrash size={18} />
                                                    Làm trống
                                                </Button>
                                            </Flex>

                                            <Table
                                                className="mt-3 border-t-dashed border-top orders-table"
                                                columns={columns}
                                                dataSource={carts.map((cart, index) => ({ key: index, ...cart }))}
                                                pagination={false}
                                            />
                                        </Fragment>
                                    ) : (
                                        <Flex justify="center" align="center" className="py-4">
                                            <div className="w-max-600 d-flex flex-column align-items-center">
                                                <img
                                                    src={imageEmptyCart}
                                                    alt="Empty Cart"
                                                    style={{ width: '100%', height: 'auto', maxWidth: 250 }}
                                                />
                                                <h3 className="mb-2 font-size-25 text-center mt-4">Giỏ hàng của bạn còn trống</h3>
                                                <div className="text-subtitle text-center">
                                                    Có vẻ như bạn chưa thêm bất cứ thứ gì vào giỏ hàng của mình. Hãy tiếp tục và khám phá
                                                    các sản phẩm của chúng tôi.
                                                </div>
                                            </div>
                                        </Flex>
                                    )}
                                </Card>

                                {carts.length > 0 && (
                                    <Card styles={{ body: { padding: '12px 18px 18px' } }} className="mt-5">
                                        <Flex className="flex-wrap gap-3 pb-2">
                                            <h3 className="font-size-18 font-bold mb-0">Phương thức thanh toán</h3>
                                        </Flex>

                                        <Radio.Group value={1} className="w-full">
                                            <Radio value={1} className="border-antd billing-payment-method_item">
                                                <Flex align="center" className="gap-2">
                                                    <Avatar
                                                        shape="square"
                                                        src={imageWallet}
                                                        style={{ fontSize: 18, width: 35, height: 35, lineHeight: 35 }}
                                                    />
                                                    <div>
                                                        <span className="mr-2">Ví Thegioicode</span>
                                                        <p className="text-primary mb-0 line-height-15 font-size-13">
                                                            Số dư: {convertCurrency(currentUser?.wallet.total_balance)}
                                                        </p>
                                                    </div>
                                                </Flex>
                                            </Radio>
                                        </Radio.Group>
                                    </Card>
                                )}
                            </Col>

                            {carts.length > 0 && (
                                <Col md={7} xs={24} style={{ padding: '0 8px' }}>
                                    <Card styles={{ body: { padding: '10px 18px 18px' } }}>
                                        <h2 className="font-size-18 mb-0 ">Mã giảm giá</h2>

                                        <div className="border-top border-t-dashed mt-2 pt-2">
                                            <Input
                                                value={voucher}
                                                onChange={(e) => setVoucher(e.target.value)}
                                                placeholder="Nhập mã voucher"
                                                prefix={<IconTicket size={18} className="text-subtitle" />}
                                                maxLength={20}
                                                disabled={carts.length < 1}
                                            />

                                            <Button
                                                type="primary"
                                                block
                                                className="mt-2"
                                                disabled={voucher.length < 1 || carts.length < 1}
                                                onClick={handleAddVoucher}
                                            >
                                                Áp dụng
                                            </Button>
                                        </div>
                                    </Card>

                                    <Card styles={{ body: { padding: '10px 18px 18px' } }} className="mt-4">
                                        <h2 className="font-size-18 mb-0">Chi tiết giá</h2>

                                        <Flex className="flex-column mt-3 border-top border-t-dashed">
                                            <Flex justify="space-between" className="py-2 border-bottom border-b-dashed">
                                                <span className="font-bold">Tổng số tiền</span>
                                                <span className="font-bold">{convertCurrency(totalPrice)}</span>
                                            </Flex>
                                            <Flex justify="space-between" className="py-2 border-bottom border-b-dashed">
                                                <span className="font-bold">Chiết khấu</span>
                                                <span className="font-bold text-danger">0đ</span>
                                            </Flex>
                                            <Flex justify="space-between" className="border-bottom py-2">
                                                <span className="font-bold">Tổng thanh toán</span>
                                                <span className="font-bold font-size-18 text-primary">{convertCurrency(totalPrice)}</span>
                                            </Flex>

                                            <div className=" mt-4">
                                                <Button type="primary" loading={loadingPayment} block onClick={handleCreateOrder}>
                                                    {loadingPayment ? (
                                                        'Đang tạo...'
                                                    ) : (
                                                        <div className="box-center gap-1">
                                                            <IconCheck size={20} />
                                                            <span className="ml-1">Xác nhận</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </div>
                                        </Flex>
                                    </Card>
                                </Col>
                            )}

                            {modalVisible && (
                                <Modal
                                    centered
                                    closable={false}
                                    open={modalVisible}
                                    onOk={handleClearCart}
                                    onCancel={() => setModalVisible(false)}
                                    width={460}
                                    okText="Xác nhận"
                                    cancelText="Hủy"
                                >
                                    <p>Bạn có muốn xóa bỏ {carts.length} sản phẩm khỏ giỏ hàng?</p>
                                </Modal>
                            )}
                        </Row>
                    </Col>
                ) : (
                    <Flex align="center" justify="center" className="w-full" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}
            </Row>
        </div>
    );
}

export default Cart;
